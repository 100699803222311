const classes = {
  large: `32px`,
  medium: `16px`,
  small: `8px`,
};

interface IHSpacerProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const HSpacer = ({ small, medium }: IHSpacerProps) => {
  let spacerWidth;

  if (small) {
    spacerWidth = classes.small;
  } else if (medium) {
    spacerWidth = classes.medium;
  } else {
    spacerWidth = classes.large;
  }
  return <div style={{ width: spacerWidth, minWidth: spacerWidth }} />;
};

export default HSpacer;
