const classes = {
  large: `32px`,
  medium: `16px`,
  small: `8px`,
};

interface IVSpacerProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const VSpacer = ({ small, medium }: IVSpacerProps) => {
  let spacerHeight;

  if (small) {
    spacerHeight = classes.small;
  } else if (medium) {
    spacerHeight = classes.medium;
  } else {
    spacerHeight = classes.large;
  }

  return <div style={{ height: spacerHeight, minHeight: spacerHeight }} />;
};

export default VSpacer;
