import { Events } from '@nx-smartmonkey/shared/services';
import mixpanel from 'mixpanel-browser';

import configuration from './configuration';

const isMixpanelEnabled = () => window.location.hostname !== `localhost`;

export class Mixpanel implements Events {
  private initialized = false;

  private token = configuration.MIXPANEL_API_KEY;

  private init() {
    if (!isMixpanelEnabled()) {
      throw new Error(`Mixpanel is not enabled`);
    }

    mixpanel.init(this.token, {
      loaded: () => {
        // eslint-disable-next-line
        console.info('Mixpanel is loaded');
      },
    });
    this.initialized = true;
  }

  track({ eventName, params }: { eventName: string; params?: Record<string, any> }) {
    try {
      if (!this.initialized) this.init();
      mixpanel.track(eventName, params ?? {});
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  register(params: Record<string, any>) {
    try {
      if (!this.initialized) this.init();
      mixpanel.register(params);
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }
}

export const mixPanel = new Mixpanel();
