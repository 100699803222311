import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '@nx-smartmonkey/ui';
import React from 'react';

interface PINProps {
  color?: string;
  style?: React.CSSProperties;
}

export const PIN = ({ color = RoutalPalette.primary40, style = {}, ...props }: PINProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" style={{ width: `18px`, height: `18px`, ...style }}>
      <g>
        <path
          d="M9 16.5C9 16.5 15 13.5 15 9V3.75L9 1.5L3 3.75V9C3 13.5 9 16.5 9 16.5Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 6C8.60218 6 8.22064 6.15804 7.93934 6.43934C7.65804 6.72064 7.5 7.10218 7.5 7.5C7.50158 7.76177 7.57164 8.01857 7.70321 8.24488C7.83479 8.47118 8.02329 8.65912 8.25 8.79V11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12C9.19891 12 9.38968 11.921 9.53033 11.7803C9.67098 11.6397 9.75 11.4489 9.75 11.25V8.79C9.97671 8.65912 10.1652 8.47118 10.2968 8.24488C10.4284 8.01857 10.4984 7.76177 10.5 7.5C10.5 7.10218 10.342 6.72064 10.0607 6.43934C9.77936 6.15804 9.39782 6 9 6Z"
          fill={RoutalPalette.neutral00}
        />
      </g>
    </SvgIcon>
  );
};
