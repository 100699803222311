import { Grid } from '@mui/material';
import { Location } from '@nx-smartmonkey/shared/domain';
import { RoutalPalette, VSpacer } from '@nx-smartmonkey/ui';

import { millisecondsToMMss } from '@nx-smartmonkey/shared/helpers';
import { CustomersStop } from '../domain/customersStop/CustomersStop';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { Text } from '../react-components/Text';
import { CustomFieldValue } from './CustomFieldValue';

interface CustomersStopInfoProps {
  stop: CustomersStop;
}

export const CustomersStopInfo = ({ stop }: CustomersStopInfoProps) => {
  const { data: project } = useRetrieveProject();

  if (stop.status !== `pending` || stop.stop_fields.length === 0) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      style={{ padding: `20px`, borderRadius: `2px`, border: `1px solid ${RoutalPalette.grey4}` }}
    >
      {stop.stop_fields.map((field) => {
        switch (field.id) {
          case `label`:
            return (
              <Grid item key={field.id}>
                <Text
                  variant="body-large"
                  isBold
                  style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
                >
                  {stop.label}
                </Text>

                <VSpacer medium />
              </Grid>
            );
          case `location`:
            return (
              <Grid item key={field.id}>
                <Text>{!field.value ? `-` : (field.value as Location).label}</Text>
                <VSpacer medium />
              </Grid>
            );
          case `phone`:
            return (
              <Grid item key={field.id}>
                <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />

                <VSpacer small />

                <Text
                  variant="button"
                  style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
                  onClick={() => {
                    if (field.value) {
                      window.location.href = `tel:${(field.value as string).replace(/ /g, ``)}`;
                    }
                  }}
                >
                  {!field.value ? `-` : (field.value as string).replace(/(\d{3})(\d{3})(\d{3})/, `$1 $2 $3`)}
                </Text>
                <VSpacer medium />
              </Grid>
            );
          case `email`:
            return (
              <Grid item key={field.id}>
                <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />

                <VSpacer small />

                <Text
                  variant="button"
                  style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
                  onClick={() => {
                    if (field.value) {
                      window.location.href = `mailto:${field.value}`;
                    }
                  }}
                >
                  {(field.value ?? `-`) as string}
                </Text>
                <VSpacer medium />
              </Grid>
            );
          case `url`:
            return (
              <Grid item key={field.id}>
                <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />

                <VSpacer small />

                <Text
                  variant="button"
                  style={{ color: project?.style?.primary_color?.value ?? RoutalPalette.primary40 }}
                  onClick={() => {
                    if (field.value) {
                      window.open(field.value as string, `_blank`);
                    }
                  }}
                >
                  {(field.value ?? `-`) as string}
                </Text>
                <VSpacer medium />
              </Grid>
            );
          case `volume`:
          case `weight`:
            return (
              <Grid item key={field.id}>
                <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />

                <VSpacer small />

                <Text>{field.value ? `${field.value} ${field.unit}` : `-- ${field.unit}`}</Text>

                <VSpacer medium />
              </Grid>
            );
          case `duration`:
            return (
              <Grid item key={field.id}>
                <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />

                <VSpacer small />

                <Text>{field.value ? millisecondsToMMss((field.value as number) * 1000) : `--:--`}</Text>

                <VSpacer medium />
              </Grid>
            );
          default:
            if (field.is_custom_field) {
              return (
                <Grid item key={field.id}>
                  <Text variant="label">{field.label}</Text>

                  <VSpacer small />

                  <CustomFieldValue field={field} />

                  <VSpacer medium />
                </Grid>
              );
            }
            return (
              <Grid item key={field.id}>
                {field.is_custom_field ? (
                  <Text variant="label">{field.label}</Text>
                ) : (
                  <Text variant="label" intlId={`customers.stop_fields.${field.id}`} />
                )}

                <VSpacer small />

                <Text>{(field as any).value}</Text>

                <VSpacer medium />
              </Grid>
            );
        }
      })}
    </Grid>
  );
};
