import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { RoutalPalette } from './Colors';
import { RoutalCursorPosProps, RoutalRipple } from './RoutalRipple';

type RoutalMobileButtonStyleType = `primary` | `secondary` | `danger`;
type RoutalMobileButtonVariantType = `flat` | `text` | `default`;

type StyledButtonProps = {
  variant?: RoutalMobileButtonVariantType;
  styleType?: RoutalMobileButtonStyleType;
  disabled?: boolean;
};

const StyledRoutalMobileButton = styled.div<StyledButtonProps>`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  height: 42px;
  padding: 4px 12px;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  ${({ variant, styleType, disabled }: StyledButtonProps) => {
    let styles = ``;

    if (!disabled) {
      styles = `${styles}
      cursor: pointer;
      & > * {
        cursor: pointer;
      }
    `;
    }

    switch (variant) {
      case `flat`:
        if (disabled) {
          styles = `${styles}
            cursor: default;
            color: ${RoutalPalette.neutral10} !important;
            border: 1px solid ${RoutalPalette.neutral10};
            background-color: transparent !important;
          `;
          break;
        }
        styles = `${styles}
          color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          border: 1px solid ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          background-color: transparent;
        `;
        break;
      case `text`:
        if (disabled) {
          styles = `${styles}
            cursor: default;
            border: 1px solid transparent;
            color: ${RoutalPalette.neutral10} !important;
          `;
          break;
        }
        styles = `${styles}
          border: 1px solid transparent;
          color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
        `;
        break;
      default:
        if (disabled) {
          styles = `${styles}
            cursor: default;
            color: ${RoutalPalette.oldWhite} !important;
            border: 1px solid ${RoutalPalette.neutral10};
            background-color: ${RoutalPalette.neutral10} !important;
          `;
          break;
        }
        styles = `${styles}
          border: 1px solid transparent;
          color: ${RoutalPalette.oldWhite};
          border: 1px solid ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          background-color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
        `;
        break;
    }

    return styles;
  }}
`;

export interface RoutalMobileButtonProps {
  id?: string;
  variant?: RoutalMobileButtonVariantType | string;
  styleType?: RoutalMobileButtonStyleType;
  style?: React.CSSProperties;
}

const defaultProps = {
  variant: `default` as RoutalMobileButtonVariantType,
  styleType: `primary` as RoutalMobileButtonStyleType,
};

const generateRandomId = () => `mobile-button-${Math.random()}`;

/**
 * General purpose button component. Allows different variants.
 * @param props
 */
export const RoutalMobileButton = ({
  id,
  children,
  disabled,
  style,
  variant,
  styleType,
  onClick,
  ...buttonProps
}: React.PropsWithChildren<RoutalMobileButtonProps> &
  React.ButtonHTMLAttributes<HTMLDivElement & RoutalMobileButtonProps> = defaultProps) => {
  const [cursorPosition, setCursorPosition] = useState<RoutalCursorPosProps | undefined>(undefined);

  const keyRef = useRef<string>(id ?? generateRandomId());

  useEffect(() => {
    if (keyRef.current === null && keyRef.current !== id) {
      keyRef.current = id ?? generateRandomId();
    }
  }, [id]);

  const updateRippleCursorPosition = ({ left, top }: { left: number; top: number }) => {
    setCursorPosition({
      top,
      left,
      // Prevent Component duplicates do ripple effect at the same time
      time: Date.now(),
    });

    setTimeout(() => {
      setCursorPosition(undefined);
    }, 300);
  };

  return (
    <StyledRoutalMobileButton
      id={keyRef.current}
      key={keyRef.current}
      style={style}
      styleType={styleType ?? `primary`}
      variant={(variant as RoutalMobileButtonVariantType) ?? `default`}
      disabled={disabled}
      {...buttonProps}
      onClick={(event: any) => {
        if (disabled) return;

        event.stopPropagation();
        if (onClick) {
          onClick(event);
        }

        // Set Cursor Position
        updateRippleCursorPosition({ left: event.clientX, top: event.clientY });
      }}
    >
      {children}
      {<RoutalRipple cursorPos={cursorPosition} />}
    </StyledRoutalMobileButton>
  );
};
