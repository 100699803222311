import { withStyles } from '@mui/styles';
import { booleanPointInPolygon, point, polygon, Position } from '@turf/turf';
import lodash from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Marker as LMarker, Popup, Tooltip } from 'react-leaflet';

import { getColorByIndex, SMPalette } from '../Colors';
import { Action } from './Map';

import styled from 'styled-components';
import { RoutalPalette } from '../../new_components';
import InstructionIcon from '../assets/svg/InstructionIcon';
import TimeIcon from '../assets/svg/TimeIcon';
import WarningIcon from '../assets/svg/WarningIcon';
import { getLeafletMarkerIcon, MarkerTypes } from './MarkerIcon';

export const isMarkerInsideCoordinates = ({
  markerCoords,
  coordinates,
}: {
  markerCoords: { lat: number; lng: number };
  coordinates: { lat?: number; lng?: number }[];
}) => {
  // IMPORTANT: We use lng/lat instead of lat/lng because of the GeoJSON format. See: https://macwright.com/lonlat/
  const markerPoint = point([markerCoords.lng, markerCoords.lat]);
  const data = [coordinates.map((coordinate) => [coordinate.lng, coordinate.lat] as Position)];
  const poly = polygon(data);
  const selected = booleanPointInPolygon(markerPoint, poly);

  return selected;
};

const styles = {
  marker: {
    boxSizing: `border-box` as any,
    height: `25px`,
    width: `25px`,
    border: `2px solid ${SMPalette[`grey2`]}`,
    borderRadius: `50%`,
    boxShadow: `0 0 0 2px white`,
    backgroundColor: `#DFE6EF`,
    fontWeight: `bold` as any,
    fontSize: `10px`,
  },
};

type GetColorProps = {
  done?: boolean;
  error?: boolean;
  incomplete?: boolean;
  selected?: boolean;
  color?: string | number;
};

const getColor = ({ done, error, incomplete, selected, color }: GetColorProps): string => {
  let iconColor;
  if (color === undefined) {
    iconColor = SMPalette[`grey2`];
  } else if (typeof color === `number`) {
    iconColor = getColorByIndex(color);
  } else {
    iconColor = color;
  }
  if (!selected) {
    if (error) {
      iconColor = `#E03030`;
    } else if (done) {
      iconColor = `#70D292`;
    } else if (incomplete) {
      iconColor = `#FFB300`;
    }
  }
  return iconColor;
};

interface MarkerProps {
  id?: string;
  lat: number;
  lng: number;
  keyMarker?: any;
  color?: string | number;
  label?: string | React.ReactElement;
  kind?: MarkerTypes;
  selected?: boolean;
  done?: boolean;
  error?: boolean;
  incomplete?: boolean;
  reorder?: boolean;
  number?: number;
  orientation?: number;
  pointer?: boolean;
  hasDelay?: boolean;
  delayLabel?: string;
  avatarUrl?: string; //avatarURL is deprecated, it doesn't use the url, it requires the image instead. Use avatar instead.
  avatar?: string;
  showAlert?: boolean;
  showInProgress?: boolean;
  showWarning?: boolean;
  onClick?: (...args: any[]) => any;
  onDoubleClick?: (...args: any[]) => any;
  doNotRefreshBounds?: boolean;
  dispatch?: React.Dispatch<Action>;
  pane?: string;
}

const Marker: FunctionComponent<MarkerProps> = ({
  id,
  lat,
  lng,
  keyMarker,
  color,
  label,
  kind,
  selected,
  done,
  error,
  incomplete,
  reorder,
  number,
  orientation,
  pointer,
  hasDelay,
  delayLabel,
  avatarUrl,
  avatar,
  showAlert,
  showInProgress,
  showWarning,
  onClick,
  onDoubleClick,
  doNotRefreshBounds,
  dispatch,
  pane,
}) => {
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [markerColor, setMarkerColor] = useState<string | number | undefined>();
  const [markerSelected, setMarkerSelected] = useState<boolean>(false);

  if (avatar && !avatarUrl) {
    //In order to use avatar instead and do not removing avatarUrl
    avatarUrl = avatar;
  }

  const setBounds = useCallback(() => {
    if (lat !== undefined && lng !== undefined) {
      const [minLat, minLng, maxLat, maxLng] = [lat, lng, lat, lng];
      if (dispatch) {
        dispatch({
          type: `ADD_BOUNDS`,
          bounds: [
            [minLat, minLng],
            [maxLat, maxLng],
          ],
          ...(id ? { id } : {}),
        });
      }
    }
  }, [dispatch, id, lat, lng]);

  useEffect(() => {
    if (!doNotRefreshBounds && (!lodash.isEqual(position[0], lat) || !lodash.isEqual(position[1], lng))) {
      setPosition([lat, lng]);
      setBounds();
    }
    if (!lodash.isEqual(markerColor, color)) {
      setMarkerColor(color);
    }
    if (selected && !lodash.isEqual(markerSelected, selected)) {
      setMarkerSelected(selected);
    }
  }, [color, doNotRefreshBounds, lat, lng, markerColor, markerSelected, position, selected, setBounds]);

  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleOnDoubleClick = (event: any) => {
    if (onDoubleClick) {
      onDoubleClick(event);
    }
  };

  const iconColor = getColor({
    done,
    error,
    incomplete,
    selected,
    color: reorder ? RoutalPalette.neutral40 : markerColor,
  });

  const getExtraIcon = () => {
    if (!showAlert && !showInProgress && !showWarning) {
      return null;
    }

    let backgroundColor, icon;

    if (showInProgress) {
      backgroundColor = RoutalPalette.success.medium;
      icon = <TimeIcon style={{ width: `12px`, height: `12px`, color: RoutalPalette.oldWhite }} />;
    } else if (showAlert) {
      backgroundColor = RoutalPalette.warning.dark;
      icon = <InstructionIcon style={{ width: `12px`, height: `12px`, color: RoutalPalette.oldWhite }} />;
    } else if (showWarning) {
      backgroundColor = RoutalPalette.error.medium;
      icon = <WarningIcon color={RoutalPalette.oldWhite} style={{ width: `12px`, height: `12px` }} />;
    }

    return (
      <CustomTooltip direction="right" offset={[-10, -10]} opacity={1} permanent>
        <div
          style={{
            background: backgroundColor,
            borderRadius: `50%`,
            height: `18px`,
            width: `18px`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          {icon}
        </div>
      </CustomTooltip>
    );
  };

  return (
    <LMarker
      key={keyMarker}
      position={[lat, lng]}
      icon={getLeafletMarkerIcon({
        kind,
        selected,
        color: iconColor,
        number,
        orientation,
        pointer,
        hasDelay,
        delayLabel,
        avatarUrl,
      })}
      eventHandlers={{
        click: handleOnClick,
        dblclick: handleOnDoubleClick,
      }}
      // pane={pane ?? undefined}
    >
      {label && <Popup>{label}</Popup>}
      {getExtraIcon()}
      {/* {(showAlert || showInProgress) && (
        <CustomTooltip direction="right" offset={[-10, -10]} opacity={1} permanent>
          <div
            style={{
              background: showInProgress ? RoutalPalette.success : RoutalPalette.warning,
              borderRadius: `50%`,
              height: `18px`,
              width: `18px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            {showInProgress ? (
              <TimeIcon alt="" style={{ width: `12px`, height: `12px`, color: RoutalPalette.oldWhite }} />
            ) : (
              <InstructionIcon alt="" style={{ width: `12px`, height: `12px`, color: RoutalPalette.oldWhite }} />
            )}
          </div>
        </CustomTooltip>
      )} */}
    </LMarker>
  );
};

export default withStyles(styles)(Marker);

const CustomTooltip = styled(Tooltip)`
  /* Add your custom styles for .custom-tooltip here */
  color: transparent;
  background-color: transparent;
  border: none;
  font-size: 16px;
  box-shadow: none;
  z-index: 999;

  &.leaflet-tooltip-right::before {
    opacity: 0;
  }
  &.leaflet-tooltip-left::before {
    opacity: 0;
  }
`;
