import { SvgIcon } from '@mui/material';
import { RoutalPalette } from '../../../Colors';

export const SuccessIcon = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26.02 26.19"
      style={{ color: RoutalPalette.oldWhite, width: `22px`, height: `22px`, ...props.style }}
    >
      <g>
        <g>
          <path d="M13.01,26.09C5.84,26.09,.01,20.26,.01,13.09S5.84,.09,13.01,.09s13,5.83,13,13-5.83,13-13,13Zm0-24C6.95,2.09,2.01,7.03,2.01,13.09s4.93,11,11,11,11-4.93,11-11S19.08,2.09,13.01,2.09Z" />
        </g>
        <g>
          <path d="M13.01,26.09C5.85,26.09,.02,20.26,.02,13.09S5.85,.1,13.01,.1s12.99,5.83,12.99,12.99-5.83,12.99-12.99,12.99Zm0-23.98C6.95,2.1,2.02,7.03,2.02,13.09s4.93,10.99,10.99,10.99,10.99-4.93,10.99-10.99S19.07,2.1,13.01,2.1Z" />
          <path d="M11.68,17.94c-.27,0-.52-.11-.71-.3l-4.32-4.36c-.39-.39-.39-1.02,0-1.41,.39-.39,1.03-.39,1.41,0l3.58,3.61,6.28-6.91c.37-.41,1-.44,1.41-.07,.41,.37,.44,1,.07,1.41l-6.98,7.69c-.18,.2-.44,.32-.72,.33h-.02Z" />
        </g>
      </g>
    </SvgIcon>
  );
};
