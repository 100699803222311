import { PopoverOrigin } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { RoutalPalette } from './Colors';
import { RoutalCursorPosProps, RoutalRipple } from './RoutalRipple';

export type RoutalButtonSizeType = `xs` | `small` | `large`;

type RoutalButtonType = `button` | `popover` | `multiselect`;

export type RoutalButtonStyleType = `primary` | `secondary` | `danger`;

export type RoutalButtonVariantType = `flat` | `text` | `default`;

type StyledButtonProps = {
  size?: RoutalButtonSizeType;
  variant?: RoutalButtonVariantType;
  styleType?: RoutalButtonStyleType;
  disabled?: boolean;
  ripple?: boolean;
};

const StyledRoutalButton = styled.div<StyledButtonProps>`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  ${({ size = `large`, variant, styleType, disabled, ripple }: StyledButtonProps) => {
    let styles = ripple
      ? `
      position: relative;
      overflow: hidden;
    `
      : ``;

    if (!disabled) {
      styles = `${styles}
      & > * {
        cursor: pointer;
      }
    `;
    }

    switch (size) {
      case `xs`:
        styles = `${styles}
          padding: 4px 12px;
        `;
        break;
      case `small`:
        styles = `${styles}
          padding: 9px 18px;
        `;
        break;
      case `large`:
      default:
        styles = `${styles}
          padding: 14px 28px;
        `;
        break;
    }

    switch (variant) {
      case `flat`:
        if (disabled) {
          styles = `${styles}
            cursor: default;
            color: #707070 !important;
            border: 1px solid #707070;
            background-color: transparent!important;
          `;
          break;
        }
        styles = `${styles}
          color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          border: 1px solid ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          background-color: transparent;
          :hover {
            border: 1px solid ${(RoutalPalette[`button`] as any)[styleType as string]?.hoverColor};
            color: ${(RoutalPalette[`button`] as any)[styleType as string]?.hoverColor};
          }
        `;
        break;
      case `text`:
        if (disabled) {
          styles = `${styles}
            cursor: default;
            color: #707070 !important;
          `;
          break;
        }
        styles = `${styles}
          border: 1px solid transparent;
          color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          :hover {
            border: 1px solid ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          }
        `;
        break;
      default:
        if (disabled) {
          styles = `${styles}
            border: none;
            cursor: default;
            color: white!important;
            background-color: #AAAAAA!important;
          `;
          break;
        }
        styles = `${styles}
          color: white;
          background-color: ${(RoutalPalette[`button`] as any)[styleType as string]?.defaultColor};
          :hover {
            color: white;
            background-color: ${(RoutalPalette[`button`] as any)[styleType as string]?.hoverColor};
          }
        `;
        break;
    }

    return styles;
  }}
`;

export interface RoutalButtonProps {
  id?: string;
  ripple?: boolean;
  variant?: RoutalButtonVariantType;
  styleType?: RoutalButtonStyleType;
  disabled?: boolean;
  style?: React.CSSProperties;
  size?: RoutalButtonSizeType;
  buttonType?: RoutalButtonType;
  anchor?: {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
  };
  popoverMaxHeight?: string;
  popoverOnBlur?: (event: any) => void;
}

const defaultProps = {
  ripple: false,
  variant: `default` as RoutalButtonVariantType,
  styleType: `primary` as RoutalButtonStyleType,
  buttonType: `button` as RoutalButtonType,
  anchor: {
    anchorOrigin: {
      vertical: `bottom`,
      horizontal: `left`,
    } as PopoverOrigin,
    transformOrigin: {
      vertical: `top`,
      horizontal: `left`,
    } as PopoverOrigin,
  },
  popoverMaxHeight: `300px`,
};

const generateRandomId = () => `button-${Math.random()}`;

/**
 * General purpose button component. Allows different variants.
 * @param props
 */
export const RoutalButton = ({
  id,
  children,
  ripple,
  variant,
  styleType,
  disabled,
  style,
  size,
  buttonType,
  anchor,
  popoverMaxHeight,
  popoverOnBlur,
  onClick,
  ...buttonProps
}: React.PropsWithChildren<RoutalButtonProps> &
  React.ButtonHTMLAttributes<HTMLDivElement & RoutalButtonProps> = defaultProps) => {
  const [cursorPosition, setCursorPosition] = useState<RoutalCursorPosProps | undefined>(undefined);
  const [open, setOpen] = useState(null);

  const keyRef = useRef<string>(id ?? generateRandomId());

  useEffect(() => {
    if (keyRef.current === null && keyRef.current !== id) {
      keyRef.current = id ?? generateRandomId();
    }
  }, [id]);

  const updateRippleCursorPosition = ({ left, top }: { left: number; top: number }) => {
    setCursorPosition({
      top,
      left,
      // Prevent Component duplicates do ripple effect at the same time
      time: Date.now(),
    });

    setTimeout(() => {
      setCursorPosition(undefined);
    }, 300);
  };

  return (
    <StyledRoutalButton
      id={keyRef.current}
      key={keyRef.current}
      style={style}
      styleType={styleType ?? `primary`}
      disabled={disabled}
      ripple={ripple}
      size={size ?? `small`}
      variant={variant ?? `default`}
      {...buttonProps}
      onClick={(event: any) => {
        event.stopPropagation();
        if (!disabled) {
          if (buttonType === `popover`) {
            setOpen(event.currentTarget);
          } else if (onClick) {
            onClick(event);
          }

          // Set Cursor Position
          updateRippleCursorPosition({ left: event.clientX, top: event.clientY });
        }
      }}
    >
      {children}
      {ripple ? <RoutalRipple cursorPos={cursorPosition} /> : null}
    </StyledRoutalButton>
  );
};
