import CheckIcon from '@mui/icons-material/Check';
import { Divider, Grid, MenuItem, Popover, PopoverOrigin } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { RoutalPalette } from './Colors';
import { RoutalCursorPosProps, RoutalRipple } from './RoutalRipple';
import { DropdownIcon } from './assets/svg/DropdownIcon';

export type RoutalSelectMultiSelectOption = {
  key: string;
  content: React.ReactElement;
  disabled?: boolean;
  checked?: boolean;
  hideCheckIcon?: boolean;
  height?: number;
  width?: number;
  listItemStyles?: React.CSSProperties;
  closeOnClick?: boolean;
  onClick: (...args: any[]) => any;
};

export type RoutalSelectMultiSelectOptions = Array<RoutalSelectMultiSelectOption | null>;

interface StyledSelectProps {
  isDisabled?: boolean;
  isHovered?: boolean;
  isFocused?: boolean;
  hiddenArrow?: boolean;
  justifyContent?: string;
}

const StyledSelect = styled(Grid)<StyledSelectProps>`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 4px 0px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent ?? `center`};
  border-radius: 10px;
  height: 36px;
  color: ${RoutalPalette.text};
  border: 1px solid ${RoutalPalette.neutral20};
  border-radius: 5px;
  width: 100%;

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    
  `}

  ${(props) =>
    props.isHovered &&
    `
    border-color: ${RoutalPalette.neutral20};
    background-color: ${RoutalPalette.neutral05};
    
  `}

  ${(props) =>
    props.isFocused &&
    `
    outline: none;
    background-color: ${RoutalPalette.neutral00};
    border-color: ${RoutalPalette.primary00};
  `}

  ${(props) =>
    props.hiddenArrow &&
    `
    padding-right: 8px;
  `}
`;

export interface RoutalSelectProps {
  id: string;
  disabled?: boolean;
  ripple?: boolean;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  icon?: React.ReactElement;
  hideArrow?: boolean;
  hideSeparator?: boolean;
  justifyContent?: string;
  arrowStyle?: React.CSSProperties;
  popoverProps: {
    options: RoutalSelectMultiSelectOptions;
    status?: string;
    maxHeight?: string;
    anchor?: {
      anchorOrigin?: PopoverOrigin;
      transformOrigin?: PopoverOrigin;
    };
    onOpen?: (event: any) => void;
    onBlur?: (event: any) => void;
  };
}

const defaultProps = {
  variant: `default`,
  disabled: false,
  containerStyle: {},
  hideArrow: false,
  hideSeparator: false,
  justifyContent: `center`,
  arrowStyle: {},
  popoverProps: {
    options: [],
    status: `default`,
    maxHeight: `300px`,
    anchor: {
      anchorOrigin: {
        vertical: `bottom`,
        horizontal: `left`,
      } as PopoverOrigin,
      transformOrigin: {
        vertical: `top`,
        horizontal: `left`,
      } as PopoverOrigin,
    },
  },
} as Omit<RoutalSelectProps, `id`>;

const generateRandomId = () => `routal-select-${Math.random()}`;

/**
 * General purpose multiselect button component. Uses the button component width type "popover".
 * @param props
 */
export const RoutalSelect = ({
  id,
  style = {},
  containerStyle = {},
  children,
  disabled,
  ripple,
  hideArrow,
  hideSeparator,
  justifyContent,
  arrowStyle,
  popoverProps,
}: React.PropsWithChildren<RoutalSelectProps> & React.HTMLAttributes<RoutalSelectProps>) => {
  const { options, maxHeight, anchor, onOpen, onBlur } = { ...defaultProps.popoverProps, ...popoverProps };

  const keyRef = useRef<string>(id ?? generateRandomId());

  useEffect(() => {
    if (keyRef.current === null && keyRef.current !== id) {
      keyRef.current = id ?? generateRandomId();
    }
  }, [id]);

  const selectRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState<RoutalCursorPosProps | undefined>(undefined);
  const [open, setOpen] = useState<Element | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [selectWidth, setSelectWidth] = useState(0);

  const updateRippleCursorPosition = ({ left, top }: { left: number; top: number }) => {
    setCursorPosition({
      top,
      left,
      // Prevent Component duplicates do ripple effect at the same time
      time: Date.now(),
    });

    setTimeout(() => {
      setCursorPosition(undefined);
    }, 300);
  };

  const handleOnHover = (e: any) => {
    e.stopPropagation();
    setIsHovered(true);
  };
  const handleOnLeave = (e: any) => {
    e.stopPropagation();
    setIsHovered(false);
  };

  return (
    <div
      key={keyRef.current}
      ref={selectRef}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnLeave}
      style={{ width: `100%`, ...containerStyle }}
    >
      <StyledSelect
        isDisabled={disabled}
        isHovered={isHovered}
        isFocused={Boolean(open)}
        hiddenArrow={hideArrow}
        justifyContent={justifyContent}
        onClick={(event: any) => {
          event.stopPropagation();
          if (!disabled) {
            if (selectRef.current) {
              setSelectWidth((selectRef.current as HTMLElement).offsetWidth);
            }
            if (onOpen) {
              onOpen(event);
            }
            setOpen(event.currentTarget);
            // Set Cursor Position
            updateRippleCursorPosition({ left: event.clientX, top: event.clientY });
          }
        }}
        style={style}
      >
        {children}
        {ripple ? <RoutalRipple cursorPos={cursorPosition} /> : null}
        {!hideArrow ? (
          <div
            style={{
              borderLeft: hideSeparator ? `none` : `1px solid ${RoutalPalette.neutral20}`,
              width: `24px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              height: `calc(100% + 8px)`,
            }}
          >
            <DropdownIcon
              color={arrowStyle?.color ?? undefined}
              style={{ transform: open ? `rotate(180deg)` : `none`, ...(arrowStyle ?? {}) }}
            />
          </div>
        ) : null}
      </StyledSelect>

      <Popover
        anchorEl={open}
        keepMounted
        open={options.length > 0 && Boolean(open)}
        onClose={(event: any) => {
          event.preventDefault();
          event.stopPropagation();
          setOpen(null);
          if (onBlur) {
            onBlur(event);
          }
        }}
        slotProps={{
          paper: {
            style: {
              border: `1px solid ${RoutalPalette.neutral10}`,
              backgroundColor: `white`,
              color: `${RoutalPalette.secondary00}`,
              boxShadow: `none`,
            },
          },
        }}
        style={{ maxHeight }}
        anchorOrigin={anchor!.anchorOrigin}
        transformOrigin={anchor!.transformOrigin}
      >
        {options.map((option: RoutalSelectMultiSelectOption | null, idx: number) => {
          if (option === null) {
            return (
              <MenuItem key={`divider${idx}`} style={{ padding: 0 }}>
                <Divider style={{ width: `100%` }} />
              </MenuItem>
            );
          }

          const {
            key,
            content,
            height,
            width,
            checked = false,
            listItemStyles = {},
            hideCheckIcon = true,
            disabled = false,
            closeOnClick = true,
            onClick,
          } = option;

          let colorByStatus = RoutalPalette.text;

          if (disabled) colorByStatus = RoutalPalette.neutral20;
          if (checked) colorByStatus = RoutalPalette.primary40;

          return (
            <MenuItem
              key={key}
              style={{
                height: `${height ?? 42}px`,
                width: width ? `${width}px` : selectWidth,
                minWidth: `200px`,
                ...listItemStyles,
              }}
              disabled={disabled}
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                onClick(event);
                if (closeOnClick) {
                  setOpen(null);
                }
              }}
            >
              <Grid
                key={key}
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
                style={{ width: `100%` }}
              >
                <div
                  style={{
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                    width: `100%`,
                    color: colorByStatus,
                  }}
                >
                  {content}
                </div>
                {!hideCheckIcon && checked ? <CheckIcon style={{ marginLeft: `8px`, color: colorByStatus }} /> : null}
              </Grid>
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};
