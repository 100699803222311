/* eslint-disable no-useless-escape */
export function validateEmail(email: string) {
  /* eslint-disable-next-line */
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

export const obfuscateEmail = (email: string) => {
  const [user, domain] = email.split(`@`);
  return `${user.slice(0, 2)}****@${domain}`;
};
